import React, { Component } from "react";

class HomeLineAnimation extends Component {
  state = {
    offsetTop: 0,
    animationStart: false
  };

  componentDidMount() {
    window.addEventListener("scroll", this.scrollAnimation);
  }

  scrollAnimation = () => {
    const lastSectionHeight = document.querySelector(
      ".home_section_3 .custom_row_wrapper"
    ).clientHeight;
    let maxOffset = this.line.clientHeight - lastSectionHeight / 2 - 30;
    const offsetTop = window.pageYOffset;
    if (offsetTop < maxOffset) {
      this.setState({ offsetTop, animationStart: false });
    } else {
      this.setState({ offsetTop: maxOffset, animationStart: true });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollAnimation);
  }

  render() {
    const { offsetTop, animationStart } = this.state;
    return (
      <div className="line-animation" ref={c => (this.line = c)}>
        <div
          className={`line-animation__thumb ${animationStart ? "running" : ""}`}
          data-translate={offsetTop}
          style={{
            top: `${offsetTop}px`
          }}
        >
          {Array(4)
            .fill(null)
            .map((item, index) => (
              <div
                className={`line-animation__thumb__arrow ${
                  animationStart ? "animate" : ""
                }`}
                key={index}
              />
            ))}
        </div>
      </div>
    );
  }
}

export default HomeLineAnimation;

import React, { Component } from "react";
import LazyLoad from "react-lazyload";
import {shuffle} from "lodash";

const LazySpinner = () => (
  <div
    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
  >
    <img src={require("../assets/img/adream_loader.gif")} alt="" />
  </div>
);

const clientsFront = [
  { src: require("../assets/img/clients/venus.jpg") },
  { src: require("../assets/img/clients/bielenda.png") },
  { src: require("../assets/img/cooperation/cooperation_logo_3.png") },
  { src: require("../assets/img/clients/krakow.png") },
  { src: require("../assets/img/clients/ibm.png") },
  { src: require("../assets/img/clients/kaercher.jpg") },
  { src: require("../assets/img/clients/steelseries.png") },
  { src: require("../assets/img/clients/lewandowski.png") }
];
const clientsBack = [
  { src: require("../assets/img/clients/hean.jpg") },
  { src: require("../assets/img/clients/office365.png") },
  { src: require("../assets/img/clients/canpack.png") },
  { src: require("../assets/img/clients/techland.jpg") },
  { src: require("../assets/img/clients/mount_blanc.jpg") },
  { src: require("../assets/img/clients/greenpoint.png") },
  { src: require("../assets/img/clients/komputronik.jpg") },
  { src: require("../assets/img/clients/malopolska.png") }
];

class HomeClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [1, 2, 3, 4, 5, 6, 7, 8],
      imageOrders: [0, 1, 2, 3, 4, 5, 6, 7],
      animationNumber: 0
    };
    this.interval = null;
    this.timeout = null;
    this.animationTimeout = null;
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.animation();
    }, 8000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearTimeout(this.timeout);
    clearTimeout(this.animationTimeout);
  }

  animation = () => {
    this.setState(
      prevState => {
        return { ...prevState, animationNumber: prevState.animationNumber + 1 };
      },
      () => {
        let { orders, imageOrders, animationNumber } = this.state;
        this.runAnimation(
          shuffle(orders),
          shuffle(imageOrders),
          animationNumber
        );
        this.props.onClientAnimation();
      }
    );
  };

  runAnimation = (order, imgOrder, animationNumber) => {
    order.forEach(
      (item, index) =>
        (this.timeout = setTimeout(() => {
          const element = document.getElementById(`image-${item}`);
          if (!element) return false;
          element.style.transform = "scale(0)";
          this.animationTimeout = setTimeout(() => {
            element.src =
              animationNumber % 2 === 0
                ? clientsFront[imgOrder[index]].src
                : clientsBack[imgOrder[index]].src;
            element.style.transform = "scale(1)";
          }, 250);
        }))
    );
  };

  render() {
    return (
      <div className="custom_row_wrapper">
        <div className="row custom_row">
          {clientsFront.map(({ src }, index) => (
            <div className="col-md-3" key={index}>
              <LazyLoad once height={160} placeholder={<LazySpinner />}>
                <img
                  className="img-fluid"
                  src={src}
                  id={`image-${index + 1}`}
                  alt=""
                  style={{ transition: "0.2s" }}
                />
              </LazyLoad>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default HomeClients;

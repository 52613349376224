import React, { Component } from "react";
import Calendar from "react-calendar";

class DateInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
      isOpen: false
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleFocus = () =>
    this.setState({ isOpen: true }, () =>
      document.addEventListener("mousedown", this.handleClickOutside)
    );

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  }
  render() {
    const { date, isOpen } = this.state;
    const { name, label, register, errors, validation, lang="pl" } = this.props;
    const weekDays = lang === "en" ? ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"] : ["N", "Pn", "Wt", "Śr", "Cz", "Pt", "So"];
    const convertDate = date =>
      date
        ? `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
        : "";

    return (
      <div className="react-calendar__container" ref={this.setWrapperRef}>
        <div className={`inp-group ${date || isOpen ? "fill" : ""}`}>
          <label
            htmlFor={name}
            className={validation && validation.required ? "required" : ""}
          >
            {label}
          </label>
          <input
            type="text"
            id={name}
            onFocus={this.handleFocus}
            onBlur={this.handleFocusOut}
            name={name}
            onChange={() => this.props.onChange}
            ref={register(validation || {})}
            value={convertDate(date)}
          />
          {!date && errors && errors[name] && (
            <span role="alert" className="error">
              {errors[name].message}
            </span>
          )}
        </div>
        {isOpen && (
          <Calendar
            prevLabel={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
              >
                <path
                  fill="#fff"
                  d="M.328 9.442l8.162 7.25c1.119.995 2.79-.622 1.666-1.62L4.058 9.655c5.94-.004 5.87-.01 11.81-.015 1.52-.001 1.52-2.293 0-2.292-5.87.005-5.732.01-11.602.015l5.926-5.426C11.3.922 9.632-.695 8.526.317L.328 7.82c-.451.414-.463 1.209 0 1.62z"
                />
              </svg>
            }
            nextLabel={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
              >
                <path
                  fill="#fff"
                  d="M16.672 7.558L8.51.308C7.39-.688 5.72.93 6.844 1.927l6.098 5.417c-5.94.004-5.87.01-11.81.015-1.52.001-1.52 2.293 0 2.292 5.87-.005 5.732-.01 11.602-.015l-5.926 5.426c-1.108 1.015.56 2.632 1.666 1.62l8.198-7.504c.451-.414.463-1.209 0-1.62z"
                />
              </svg>
            }
            locale={lang}
            minDetail="decade"
            formatShortWeekday={(locale, date) => `${weekDays[date.getDay()]}`}
            minDate={new Date()}
            onChange={date => this.setState({ date, isOpen: false })}
          />
        )}
      </div>
    );
  }
}

export default DateInput;

//

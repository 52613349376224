import React, { Component } from "react";
import { CookiesProvider, withCookies } from "react-cookie";
import scrollToElement from "scroll-to-element";

import { Link } from "gatsby";
import { Header, Footer, SEO } from "./";
import "../styles/theme.scss";
import locales from "../constants";

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]');
}

class Layout extends Component {
  state = {
    cookieOpen: false,
  };
  componentDidMount() {
    const { cookies } = this.props;
    const isAcceptedCoookie = !!cookies.get("cookie-accept");
    !isAcceptedCoookie && this.setState({ cookieOpen: true });

    // setTimeout(() => {
    //   this.mainMargin();
    // }, 500);
    // window.addEventListener("resize", this.mainMargin);
  }

  acceptCookie = () => {
    const { cookies } = this.props;

    const promiseSetCookie = new Promise((resolve) =>
      resolve(cookies.set("cookie-accept", "active", { path: "/" }))
    );
    promiseSetCookie.then(() => {
      this.setState({ cookieOpen: false });
    });
  };

  // mainMargin() {
  //   const footer = document.getElementsByTagName("footer");
  //   if (!footer) return false;
  //   const footerHeight = footer[0].clientHeight;
  //   document.getElementsByClassName(
  //     "main"
  //   )[0].style.paddingTop = `${footerHeight}px`;

  //   if (browser.name === "firefox") {
  //     document.getElementsByClassName(
  //       "main"
  //     )[0].style.marginTop = `-${footerHeight}px`;
  //   } else {
  //     document.getElementsByClassName(
  //       "main"
  //     )[0].style.top = `-${footerHeight}px`;
  //   }

  //   document.getElementsByClassName(
  //     "main"
  //   )[0].style.marginBottom = `${footerHeight}px`;
  // }

  render() {
    const { children, seo, header, langSwitch } = this.props;
    return (
      <CookiesProvider>
        <SEO {...seo} />
        <Header
          {...seo}
          {...header}
          {...langSwitch}
          scroll={this.props.scroll}
        />
        <main className="main">
          {children}
          <button
            className="scrollTo"
            onClick={() => scrollToElement("#top")}
            aria-label="Scroll To Element"
          />
        </main>
        <Footer {...seo} />
        <div
          className={`cookie_bar ${this.state.cookieOpen ? "open" : ""}`}
          id="mainBelt"
        >
          <p>{locales[seo.lang].cookie_info}</p>
          <ul>
            <li>
              <button className="accept_btn" onClick={this.acceptCookie}>
                Ok
              </button>
            </li>
            <li>
              <Link
                to={locales[seo.lang].privacy_policy_href}
                className="accept_btn"
                target="_blank"
                rel="noopener noreferrer"
              >
                {locales[seo.lang].more_info}
              </Link>
            </li>
          </ul>
        </div>
      </CookiesProvider>
    );
  }
}

export default withCookies(Layout);

import React from "react";
import { Link } from "gatsby";
import Slider from "react-slick";

const MddNav = ({ data: { initialSlideIndex }, locales }) => {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 400,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: initialSlideIndex,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1099,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="portfolio_section_nav" style={{paddingTop: "0"}}>
      <Slider {...settings}>
        <div>
          <Link to="/projekty/bogucianka" className="portfolio_tile">
            <h3>
              Osiedle Bogucianka
              <span>{locales.portfolio_navs.bogucianka}</span>
            </h3>
            <div
              className="portfolio_tile-back"
              style={{
                backgroundImage: `url(${require("../assets/img/portfolio_grid/bogucianka_back.png")})`,
              }}
            ></div>
            <div
              className="portfolio_tile-back"
              style={{
                backgroundImage: `url(${require("../assets/img/portfolio_grid/bogucianka_front.png")})`,
              }}
            ></div>
          </Link>
        </div>
        <div>
          <Link to="/projekty/vortune" className="portfolio_tile">
            <h3>
              Vortune Equity
              <span>{locales.portfolio_navs.vortune}</span>
            </h3>
            <div
              className="portfolio_tile-back"
              style={{
                backgroundImage: `url(${require("../assets/img/portfolio_grid/vortune_back.png")})`,
              }}
            ></div>
            <img
              className="img-fluid"
              src={require("../assets/img/portfolio_grid/vortune_front.png")}
              alt=""
            />
          </Link>
        </div>
        <div>
          <Link to="/projekty/rezydencja-gubalowka" className="portfolio_tile">
            <h3>
              Rezydencja Gubałówka
              <span>{locales.portfolio_navs.gubalowka}</span>
            </h3>
            <div
              className="portfolio_tile-back"
              style={{
                backgroundImage: `url(${require("../assets/img/portfolio_grid/gubalowka_back.png")})`,
              }}
            ></div>
            <img
              className="img-fluid"
              src={require("../assets/img/portfolio_grid/gubalowka_front.png")}
              alt=""
            />
          </Link>
        </div>
        <div>
          <Link to="/projekty/horizon" className="portfolio_tile">
            <h3>
              Horizon
              <span>{locales.portfolio_navs.horizon}</span>
            </h3>
            <div
              className="portfolio_tile-back"
              style={{
                backgroundImage: `url(${require("../assets/img/portfolio_grid/horizon_back.png")})`,
              }}
            />
            <img
              className="img-fluid"
              src={require("../assets/img/portfolio_grid/horizon_front.png")}
              alt=""
            />
          </Link>
        </div>
        <div>
          <Link
            to="/projekty/green-mountain-resort"
            className="portfolio_tile gmr"
          >
            <h3>
              Green Mountain Resort{" "}
              <span>{locales.portfolio_navs.gmr}</span>
            </h3>
            <div
              className="portfolio_tile-back"
              style={{
                backgroundImage: `url(${require("../assets/img/portfolio_grid/gmr_back.png")})`,
              }}
            ></div>
            <img
              className="img-fluid"
              src={require("../assets/img/portfolio_grid/gmr_front.png")}
              alt=""
            />
          </Link>
        </div>
        <div>
            <Link
              to="/projekty/crystal-mountain"
              className="portfolio_tile"
            >
              <h3>
                Crystal Mountain<span> Strona internetowa / Materiały marketingowe </span>
              </h3>
              <div
                className="portfolio_tile-back"
                style={{
                  backgroundImage: `url(${require("../assets/img/portfolio_grid/cm_back.png")})`,
                }}
              ></div>
              <img
                className="img-fluid"
                src={require("../assets/img/portfolio_grid/cm_front.png")}
                alt=""
              />
            </Link>
          </div>
          <div>
            <Link
              to="/projekty/kw51"
              className="portfolio_tile targetAll targetBranding"
            >
              <h3>
                KW51
                <span>Logo / Strona internetowa / Materiały marketingowe</span>
              </h3>
              <div
                className="portfolio_tile-back"
                style={{
                  backgroundImage: `url(${require("../assets/img/portfolio_grid/kw51_back.png")})`,
                }}
              ></div>
              <img
                className="img-fluid"
                src={require("../assets/img/portfolio_grid/kw51_front.png")}
                alt=""
              />
            </Link>
          </div>
          <div>
            <Link
              to="/projekty/unity-centre"
              className="portfolio_tile targetAll targetBranding"
            >
              <h3>
                Unity Centre
                <span>Strona internetowa</span>
              </h3>
              <div
                className="portfolio_tile-back"
                style={{
                  backgroundImage: `url(${require("../assets/img/portfolio_grid/unity-back.jpg")})`,
                }}
              ></div>
              <img
                className="img-fluid"
                src={require("../assets/img/portfolio_grid/unity-front.png")}
                alt=""
              />
            </Link>
          </div>
          <div>
            <Link
              to="/projekty/niegolewskich14"
              className="portfolio_tile targetAll targetBranding"
            >
              <h3>
                Niegolewskich 14
                <span>Strona internetowa / Kampania Online</span>
              </h3>
              <div
                className="portfolio_tile-back"
                style={{
                  backgroundImage: `url(${require("../assets/img/portfolio_grid/niegolewskich_back.png")})`,
                }}
              ></div>
              <img
                className="img-fluid"
                src={require("../assets/img/portfolio_grid/niegolewskich_front.png")}
                alt=""
              />
            </Link>
          </div>
          <div>
            <Link
              to="/projekty/biala-perla"
              className="portfolio_tile targetAll targetBranding"
            >
              <h3>
                Biała Perła
                <span>{locales.portfolio_navs.biala_perla}</span>
              </h3>
              <div
                className="portfolio_tile-back"
                style={{
                  backgroundImage: `url(${require("../assets/img/portfolio_grid/biala_perla_back.png")})`,
                }}
              ></div>
              <img
                className="img-fluid"
                src={require("../assets/img/portfolio_grid/biala_perla_front.png")}
                alt="Biała Perła budynek"
              />
            </Link>
          </div>
          <div>
            <Link
              to="/projekty/condo"
              className="portfolio_tile targetAll targetBranding"
            >
              <h3>
                Condo
                <span>{locales.portfolio_navs.condo}</span>
              </h3>
              <div
                className="portfolio_tile-back"
                style={{
                  backgroundImage: `url(${require("../assets/img/portfolio_grid/condo_back.png")})`,
                }}
              ></div>
              <img
                className="img-fluid"
                src={require("../assets/img/portfolio_grid/condo_front.png")}
                alt="Condo budynek"
              />
            </Link>
          </div>
          <div>
            <Link
              to="/projekty/moja-klonowa"
              className="portfolio_tile targetAll targetBranding"
            >
              <h3>
                Moja Klonowa
                <span>{locales.portfolio_navs.klonowa}</span>
              </h3>
              <div
                className="portfolio_tile-back"
                style={{
                  backgroundImage: `url(${require("../assets/img/portfolio_grid/klonowa_back.png")})`,
                }}
              ></div>
              <img
                className="img-fluid"
                src={require("../assets/img/portfolio_grid/klonowa_front.png")}
                alt="Moja Klonowa budynek"
              />
            </Link>
          </div>
      </Slider>
    </section>
  );
};

export default MddNav;
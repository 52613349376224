import React, { Component } from "react";

class Input extends Component {
  state = {
    filled: false,
  };

  handleFocus = () => this.setState({ filled: true });

  checkInput = ({ target: { value } }) => {
    if (value.length > 0) {
      this.setState({ filled: true });
    } else {
      this.setState({ filled: false });
    }
  };
  render() {
    const {
      type,
      name,
      label,
      register,
      value,
      errors,
      validation,
      hidden,
      required,
      mb,
    } = this.props;
    const { filled } = this.state;
    return (
      <div className={`inp-group ${filled ? "fill" : ""} ${mb ? "mb-30" : ""}`}>
        <label
          htmlFor={name}
          className={validation && validation.required ? "required" : ""}
        >
          {label}
        </label>
        <input
          id={name}
          onFocus={this.handleFocus}
          onBlur={this.checkInput}
          type={type}
          name={name}
          autoComplete="off"
          ref={register(validation || {})}
          defaultValue={value || ""}
          hidden={hidden}
          required={required}
        />
        {errors && errors[name] && (
          <span role="alert" className="error">
            {errors[name].message}
          </span>
        )}
      </div>
    );
  }
}

export default Input;

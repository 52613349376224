import { withPrefix } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

const SEO = ({
  yoast_wpseo_canonical,
  yoast_wpseo_metadesc,
  yoast_wpseo_title,
  title,
  featuredImage,
  href,
  lang,
  next,
  prev,
  customCanonical,
  ogImage,
  noIndex,
}) => {
  let seo_title = yoast_wpseo_title || title;
  seo_title = `${seo_title} ${
    seo_title.includes("Grupa ADream")
      ? ""
      : "| Grupa ADream - Agencja reklamowa interaktywna w Krakowie"
  }`;
  const metaDesc =
    lang === "en"
      ? "Your external marketing department - marketing strategy, lead generation campaigns, branding, web development."
      : "Twój zewnętrzny dział marketingu - strategia marketingowa, branding, usługi internetowe, marketing.";

  return (
    <Helmet title={seo_title}>
      <html lang="pl-PL" />
      <meta
        property="og:image"
        content={
          featuredImage
            ? featuredImage
            : ogImage
            ? ogImage
            : withPrefix("/og-image.png")
        }
      />
      <meta name="description" content={metaDesc} />
      {customCanonical && <link rel="canonical" href={customCanonical} />}
      <link rel="profile" href="https://gmpg.org/xfn/11" />
      <meta name="msapplication-TileColor" content="#2d89ef" />
      <meta name="theme-color" content="#ffffff" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={metaDesc} />
      <meta property="og:site_name" content="ADream" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={metaDesc} />
      {next && <link rel="next" href={`${href}${next}`} />}
      {prev && <link rel="prev" href={`${href}${prev}`} />}
      <meta property="og:title" content={`${yoast_wpseo_title || title}`} />
      <meta property="og:url" content={href} />
      <meta name="twitter:title" ontent={`${yoast_wpseo_title || title}`} />
      <meta
        name="google-site-verification"
        content="B8luzQMq1JoKHRfPCnlH65Fv0ZUv8kgDDVF7bpQ-FXw"
      />
      {noIndex === true && <meta name="robots" content="noindex" />}
    </Helmet>
  );
};

export default SEO;

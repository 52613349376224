import React, { useState } from "react";
import { navigate } from "gatsby";
import useForm from "react-hook-form";
import axios from "axios";
import Confetti from "react-dom-confetti";
import { Input, Check, Textarea, Loader } from "../components";

const ContactForm = ({ locales, lang }) => {
  const { register, errors, handleSubmit, triggerValidation } = useForm({
    mode: "onChange",
  });

  const config = {
    angle: 90,
    spread: 100,
    startVelocity: 60,
    elementCount: 150,
    dragFriction: 0.1,
    duration: 2000,
    stagger: 0,
    width: "7px",
    height: "10px",
    colors: [
      "#E68F17",
      "#FAB005",
      "#FA5252",
      "#E64980",
      "#BE4BDB",
      "#0B7285",
      "#15AABF",
      "#EE1233",
      "#40C057",
    ],
  };

  const formID = lang === "en" ? "1748" : "1747";
  const [isLoading, setLoading] = useState(false);
  const [showConfetti, triggerConfetti] = useState(false);

  const onSubmit = (data, e) => {
    e.preventDefault();
    setLoading(true);
    triggerConfetti(true);

    let formData = new FormData();

    formData.set("email", data.email);
    formData.set("phone", data.phone);
    formData.set("prefered", data.prefered);
    formData.set("message", data.message);
    formData.set("term1", data.term1);
    formData.set("term2", data.term2);
    formData.set("_wpcf7_unit_tag", "#wpcf7_unit_tag-" + formID);

    axios({
      method: "post",
      url: `https://wp.adream.pl/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(() => {
        setTimeout(() => {
          setLoading(false);
          triggerConfetti(false);
          navigate(locales.thank_u);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="wpcf7">
      {isLoading && <Loader />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          type="email"
          name="email"
          label="E-mail"
          register={register}
          validation={{
            required: locales.required,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i,
              message: locales.email_error,
            },
          }}
          errors={errors}
        />
        <Input
          type="phone"
          name="phone"
          label={locales.phone}
          register={register}
          validation={{
            required: locales.required,
          }}
          errors={errors}
        />
        <div className="inp-group">
          <div className="select_wrapper">
            <select ref={register} name="prefered" defaultValue="empty">
              <option value="empty" disabled>
                {locales.prefered}
              </option>
              <option value="E-mail">E-mail</option>
              <option value="Telefon">{locales.phone}</option>
            </select>
          </div>
        </div>
        <Textarea
          cols="40"
          rows="6"
          name="message"
          label={locales.message}
          register={register}
          validation={{
            required: locales.required,
            contents: locales.email_error,
          }}
          errors={errors}
        />
        <Check
          register={register}
          name="term1"
          size="sm"
          validation={{ required: locales.required }}
          errors={errors}
          required
          label={
            <>
              {locales.legal_info}{" "}
              <a
                href={locales.privacy_policy_href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {locales.privacy_policy_form}
              </a>
            </>
          }
        />
        <Check
          register={register}
          name="term2"
          size="sm"
          errors={errors}
          label={locales.legal_third}
        />
        <div className="btn_wrapper">
          <input
            type="submit"
            value={locales.contact_form_send}
            className="wpcf7-form-control wpcf7-submit"
            onClick={() => {
              triggerValidation();
            }}
          />
          <Confetti active={showConfetti} config={config} />
        </div>
      </form>
    </div>
  );
};

export default ContactForm;

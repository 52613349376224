import "./particles.scss";
import React from "react";

const HeroStars = () => {
  return (
    <div className="stars-container">
      {Array(100)
        .fill(null)
        .map((item, index) => (
          <div className="star-container" key={index}>
            <div className="star"></div>
          </div>
        ))}
    </div>
  );
};

export default HeroStars;

import React, { Component } from "react";
import scrollToElement from "scroll-to-element";
import Particles from "./Particles/Particles";

class PortfolioHeader extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this.heroFade);
  }
  heroFade = () => {
    const heroFade = window.pageYOffset || document.documentElement.scrollTop;
    const header = document.getElementsByClassName("heroFade")[0];
    if (!header) return false;
    header.style.transform = "scale(" + (100 - heroFade / 50) / 98 + ")";
    header.style.opacity = 1 - heroFade / 40 / 50;
    header.style.transition = "0.2s";
  };
  getImage = (name) => {
    try {
      return require(`../assets/img/portfolio/${name}_top_logo.svg`);
    } catch (error) {
      return require(`../assets/img/portfolio/${name}_top_logo.png`);
    }
  };
  render() {
    const { name, height, video, show_particles = false } = this.props;

    return (
      <section className="heroFadeWrapper">
        {/* eslint-disable jsx-a11y/control-has-associated-label */}
        <div
          className={`heroFade ${
            video ? "heroFade--video" : ""
          } ${name}_section_1`}
          style={{
            backgroundImage: `url(${
              !video && require(`../assets/img/portfolio/${name}_top_back.jpg`)
            })`,
          }}
        >
          {video ? (
            <video
              muted
              autoPlay="autoplay"
              loop={true}
              height="100%"
              width="100%"
              playsInline
            >
              <source
                src="https://wp.adream.pl/adream-content/bialaperla/bialaperla-spot.mp4"
                type="video/mp4"
              />
            </video>
          ) : (
            <img
              className={`img-fluid img_${name}`}
              src={this.getImage(name)}
              style={{
                maxHeight: `${height}px`,
              }}
              alt={`${name} Logo`}
            />
          )}
          <button
            className="scrollPortfolio"
            onClick={() => scrollToElement("#info")}
          />
        </div>
        {show_particles && <Particles />}
      </section>
    );
  }
}

export default PortfolioHeader;

/* eslint-disable */

import React from "react";

const Radio = ({
  id,
  name,
  label,
  register,
  errors,
  value,
  validation,
  onClick,
}) => {
  return (
    <div className={`radio-group`} onClick={onClick}>
      <input
        type="radio"
        id={id}
        name={name}
        defaultValue={value || id}
        ref={register(validation || {})}
      />
      <label htmlFor={id}>{label}</label>
      {errors && errors[name] && (
        <span role="alert" className="error">
          {errors[name].message}
        </span>
      )}
    </div>
  );
};

export default Radio;

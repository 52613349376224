import React from "react";
import { Link } from "gatsby";
import locales from "../constants";

const Footer = ({ lang }) => {
  const translation = locales[lang];
  const { title, city, street } = translation.address;
  const { tittle, email } = translation.contact;
  const { title2, link, policyname } = translation.policy;

  return (
    <>
      <footer>
        <div className="row">
          <div className="col-md-6">
            <p>{translation.menu}</p>
            <ul className="list_nav">
              {translation.footer_menu.map(({ title, link }) => (
                <li key={title}>
                  <Link to={link}>{title}</Link>
                </li>
              ))}
            </ul>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              x="0px"
              y="0px"
              viewBox="0 0 149 39"
              className="svg footer_logo replaced-svg"
            >
              <g>
                <path
                  fill="#fff"
                  d="M138.2,5.9L138.2,5.9c-1.6,0-2.9-1.3-2.9-2.9v0c0-1.6,1.3-2.9,2.9-2.9l0,0c1.6,0,2.9,1.3,2.9,2.9v0   C141.1,4.6,139.8,5.9,138.2,5.9z"
                />
                <path
                  fill="#3B00A6"
                  d="M130.3,5.9L130.3,5.9c-1.6,0-2.9-1.3-2.9-2.9v0c0-1.6,1.3-2.9,2.9-2.9l0,0c1.6,0,2.9,1.3,2.9,2.9v0   C133.3,4.6,131.9,5.9,130.3,5.9z"
                />
                <path
                  fill="#3B00A6"
                  d="M138.2,13.7L138.2,13.7c-1.6,0-2.9-1.3-2.9-2.9v0c0-1.6,1.3-2.9,2.9-2.9l0,0c1.6,0,2.9,1.3,2.9,2.9v0   C141.1,12.4,139.8,13.7,138.2,13.7z"
                />
                <path
                  fill="#3B00A6"
                  d="M146,13.7L146,13.7c-1.6,0-2.9-1.3-2.9-2.9v0c0-1.6,1.3-2.9,2.9-2.9l0,0c1.6,0,2.9,1.3,2.9,2.9v0   C148.9,12.4,147.6,13.7,146,13.7z"
                />
                <path
                  fill="#3B00A6"
                  d="M146,21.6L146,21.6c-1.6,0-2.9-1.3-2.9-2.9v0c0-1.6,1.3-2.9,2.9-2.9l0,0c1.6,0,2.9,1.3,2.9,2.9v0   C148.9,20.2,147.6,21.6,146,21.6z"
                />
                <path
                  fill="#3B00A6"
                  d="M146,5.9L146,5.9c-1.6,0-2.9-1.3-2.9-2.9v0c0-1.6,1.3-2.9,2.9-2.9l0,0c1.6,0,2.9,1.3,2.9,2.9v0   C148.9,4.6,147.6,5.9,146,5.9z"
                />
              </g>
              <g>
                <path
                  fill="#3B00A6"
                  d="M4.6,39H0.6c-0.5,0-0.7-0.3-0.5-0.9l6.7-25.4c0.2-0.6,0.6-0.9,1.3-0.9h5.6c0.7,0,1.1,0.3,1.3,0.9l6.7,25.4   c0.2,0.6,0,0.9-0.5,0.9H17c-0.3,0-0.5-0.1-0.6-0.2c-0.1-0.1-0.2-0.4-0.3-0.7l-1.2-4.6H6.7l-1.2,4.6c-0.1,0.3-0.1,0.6-0.3,0.7   C5.1,38.9,4.9,39,4.6,39z M10.8,16.9l-3,12.2H14l-3-12.2H10.8z"
                />
                <path
                  fill="#3B00A6"
                  d="M50.3,39h-3.6c-0.6,0-0.9-0.3-0.9-0.9V18.3c0-0.6,0.3-0.9,0.9-0.9h3.6c0.6,0,0.9,0.3,0.9,0.9v1.4h0.2   c0.3-0.8,0.9-1.3,1.6-1.7c0.8-0.4,1.6-0.6,2.5-0.6H57c0.6,0,0.8,0.3,0.8,0.9v2.9c0,0.3-0.1,0.6-0.2,0.7c-0.1,0.1-0.3,0.2-0.7,0.1   h-2.9c-1.9,0-2.9,1.1-2.9,3.3v12.7C51.1,38.7,50.8,39,50.3,39z"
                />
                <path
                  fill="#3B00A6"
                  d="M76.5,39h-9.3c-2.2,0-3.9-0.6-5.2-1.8s-1.8-2.9-1.8-5.1v-7.8c0-2.2,0.6-3.9,1.8-5.1s2.9-1.8,5.2-1.8h3.3   c2.2,0,3.9,0.6,5.2,1.8c1.2,1.2,1.8,2.9,1.8,5.1v5.4c0,0.6-0.3,0.9-0.9,0.9H66c-0.3,0-0.5,0.2-0.5,0.5V32c0,0.9,0.2,1.5,0.6,1.9   c0.4,0.4,1,0.6,1.9,0.6h8.5c0.6,0,0.9,0.3,0.9,0.9v2.8c0,0.3-0.1,0.5-0.2,0.7C77.1,38.9,76.8,39,76.5,39z M66,27h5.7   c0.3,0,0.5-0.2,0.5-0.5v-2c0-0.9-0.2-1.5-0.5-1.9c-0.4-0.4-1-0.5-1.9-0.5H68c-0.9,0-1.5,0.2-1.9,0.5c-0.4,0.4-0.5,1-0.5,1.9v2   C65.5,26.8,65.7,27,66,27z"
                />
                <path
                  fill="#3B00A6"
                  d="M88.8,39h-1c-2.2,0-3.9-0.6-5.2-1.8c-1.2-1.2-1.8-2.9-1.8-5.1v-7.8c0-2.2,0.6-3.9,1.8-5.1   c1.2-1.2,2.9-1.8,5.2-1.8h9.8c0.6,0,0.9,0.3,0.9,0.9v19.8c0,0.6-0.3,0.9-0.9,0.9H94c-0.6,0-0.9-0.3-0.9-0.9v-1.4h-0.2   c-0.3,0.8-0.9,1.3-1.6,1.7C90.5,38.8,89.6,39,88.8,39z M93.1,31.1v-8.6c0-0.3-0.2-0.5-0.5-0.5h-4c-0.9,0-1.5,0.2-1.9,0.6   c-0.4,0.4-0.5,1-0.5,1.9v7.3c0,0.9,0.2,1.5,0.5,1.9c0.4,0.4,1,0.6,1.9,0.6h1.6C92.1,34.4,93.1,33.3,93.1,31.1z"
                />
                <path
                  fill="#3B00A6"
                  d="M106.5,39h-3.6c-0.6,0-0.9-0.3-0.9-0.9V18.3c0-0.6,0.3-0.9,0.9-0.9h3.6c0.6,0,0.9,0.3,0.9,0.9v1.4h0.2   c0.3-0.7,0.9-1.2,1.7-1.6c0.8-0.4,1.7-0.6,2.8-0.6h1.2c2.5,0,4.2,0.9,5.3,2.7c1-1.8,2.6-2.7,4.9-2.7h1.2c2.2,0,3.8,0.6,4.9,1.8   c1.1,1.2,1.7,2.9,1.7,5.1v13.8c0,0.6-0.3,0.9-0.9,0.9h-3.6c-0.6,0-0.9-0.3-0.9-0.9V24.6c0-0.9-0.2-1.5-0.5-1.9   c-0.4-0.4-1-0.6-1.9-0.6h-1.1c-1.9,0-2.9,1.1-2.9,3.3v12.8c0,0.6-0.3,0.9-0.9,0.9H115c-0.6,0-0.9-0.3-0.9-0.9V24.6   c0-0.9-0.2-1.5-0.6-1.9s-1-0.6-1.9-0.6h-1.3c-1.9,0-2.9,1.1-2.9,3.2v12.8C107.4,38.7,107.1,39,106.5,39z"
                />
                <path
                  fill="#3B00A6"
                  d="M33.4,11.8L33.4,11.8l-8.5,0c-0.6,0-0.9,0.3-0.9,0.9v25.4c0,0.6,0.3,0.9,0.9,0.9h8.5v0c5-0.1,8.9-4.2,8.9-9.2   V21C42.3,16,38.3,11.9,33.4,11.8z M36.9,29.8c0,2-1.7,3.7-3.7,3.8l-0.2,0h-3.7V17.2h3.7l0.2,0c2,0.1,3.7,1.7,3.7,3.8V29.8z"
                />
              </g>
            </svg>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6">
                <p>{title}</p>
                <address>
                  {street} <br />
                  {city}
                </address>
              </div>
              <div className="col-md-6">
                <p>{tittle}</p>
                <contact>
                  <a href="tel:+48123078880" className="link">
                    12 307 88 80
                  </a>
                  <a href={"mailto:" + email} className="link">
                    {email}
                  </a>
                </contact>
              </div>
            </div>
            <div
              className="clutch-widget"
              data-url="https://widget.clutch.co"
              data-widget-type="7"
              data-height="65"
              data-nofollow="true"
              data-expandifr="true"
              data-scale="100"
              data-clutchcompany-id="2016510"
            ></div>
            <div className="row align-items-center flex-column-reverse flex-lg-row row_sec">
              <div className="col-lg-8">
                <div className="copyrights">
                  &copy; {new Date().getFullYear()} {title2} <br />
                  <policy>
                    <Link to={link}>{policyname}</Link>
                  </policy>
                </div>
              </div>
              <div className="col-lg-3">
                <ul className="list_social">
                  <li>
                    <a
                      href="https://www.facebook.com/adream.pl"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid"
                        src={require("../assets/img/ico_fb.svg")}
                        alt="ADream Facebook"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

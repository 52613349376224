import React, { useState } from "react";
import { navigate } from "gatsby";
import useForm from "react-hook-form";
import Helmet from "react-helmet";
import axios from "axios";
import Confetti from "react-dom-confetti";
import { Input, Textarea, Check, Loader } from "../components";

const RecrutationForm = ({ position }) => {
  const { register, errors, handleSubmit, triggerValidation } = useForm({
    mode: "onChange",
  });
  const config = {
    angle: 90,
    spread: 100,
    startVelocity: 60,
    elementCount: 150,
    dragFriction: 0.1,
    duration: 2000,
    stagger: 0,
    width: "7px",
    height: "10px",
    colors: [
      "#E68F17",
      "#FAB005",
      "#FA5252",
      "#E64980",
      "#BE4BDB",
      "#0B7285",
      "#15AABF",
      "#EE1233",
      "#40C057",
    ],
  };
  const [isLoading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [showConfetti, triggerConfetti] = useState(false);

  const onSubmit = async (data, e) => {
    const isOk = await triggerValidation();
    if (!isOk) return false;
    if (data.bot_field) return false;
    delete data.bot_field;
    triggerConfetti(true);
    setTimeout(() => {
      const client = window.filestack.init("ADsxs72zQjmoVTxvO7P6lz");
      setLoading(true);
      if (files.length > 0) {
        client
          .upload(files[0])
          .then(({ url }) => {
            axios
              .post("https://adream-mailing.herokuapp.com/recrutation", {
                ...data,
                files: [{ url }],
              })
              .then(() => {
                setLoading(false);
                triggerConfetti(false);
                e.target.reset();
                navigate("/dziekujemy-za-kontakt/");
              })
              .catch((error) => {
                console.log(error);
                setLoading(false);
              });
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      } else {
        axios
          .post("https://adream-mailing.herokuapp.com/recrutation", {
            ...data,
          })
          .then(() => {
            setLoading(false);
            triggerConfetti(false);
            e.target.reset();
            navigate("/kariera-dziekujemy-za-kontakt/");
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
    }, 2000);
    return null;
  };

  return (
    <div className="recrutaction-form">
      <Helmet>
        <script src="//static.filestackapi.com/filestack-js/3.x.x/filestack.min.js"></script>
      </Helmet>
      <small>
        Osoby zainteresowane prosimy o przesyłanie aplikacji za pomocą
        formularza. Prosimy o dopisanie następującej klauzuli: „Wyrażam zgodę na
        przetwarzanie moich danych osobowych zawartych w mojej ofercie pracy dla
        potrzeb niezbędnych do realizacji procesu rekrutacji zgodnie z ustawą z
        dnia 29 sierpnia 1997 r. o ochronie danych osobowych (Dz. U. z 2002 r.
        Nr 101, poz. 926, ze zm.).”
      </small>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          type="hidden"
          name="position"
          value={position}
          register={register}
        />
        <input
          type="checkbox"
          name="bot_field"
          ref={register}
          className="bot-field"
        />
        <div className="row">
          <div className="col-md-4">
            <Input
              type="text"
              name="name_surname"
              label="Imię i nazwisko"
              register={register}
              validation={{
                required: "Wymagane",
              }}
              errors={errors}
            />
          </div>
          <div className="col-md-4">
            <Input
              type="email"
              name="email"
              label="Adres e-mail"
              register={register}
              validation={{
                required: "Wymagane",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i,
                  message: "Błędny adres e-mail",
                },
              }}
              errors={errors}
            />
          </div>
          <div className="col-md-4">
            <Input
              type="tel"
              name="phone"
              label="Telefon kontaktowy"
              register={register}
              validation={{
                required: "Wymagane",
              }}
              errors={errors}
            />
          </div>
        </div>
        <Input
          type="text"
          name="salary_test"
          validation={{
            required: "Wymagane",
          }}
          label="Oczekiwane wynagrodzenie netto na okresie testowym (do 3 miesięcy)"
          register={register}
          errors={errors}
        />
        <Input
          type="text"
          name="salary_after_test"
          validation={{
            required: "Wymagane",
          }}
          label="Oczekiwane wynagrodzenie po okresie testowym"
          register={register}
          errors={errors}
        />
        <p>Możliwa forma współpracy z agencją:</p>

        <div className="check-row">
          <Check
            register={register}
            name="contract_for_specific_work"
            label="umowa o dzieło"
          />
          <Check
            register={register}
            name="contract_of_mandate"
            label="umowa o zlecenie"
          />
          <Check
            register={register}
            name="contract_of_employment"
            label="umowa o pracę"
          />
          <Check register={register} name="b2b" label="B2B" />
        </div>
        <Textarea
          register={register}
          cols="40"
          rows="6"
          name="message"
          label="Treść wiadomości"
        />
        <div className="attachments">
          <input
            type="file"
            id="upload"
            name="upload"
            onChange={(e) => setFiles(e.target.files)}
            style={{ display: "none" }}
          />
          <label htmlFor="upload" className="btn btn-default">
            Załącz CV
          </label>
          {files && files.length > 0 && (
            <p>
              <span>{files[0].name}</span>
            </p>
          )}
          {errors && errors.upload && (
            <p style={{ fontSize: "12px", color: "#4a059c" }}>
              {errors.upload.message}
            </p>
          )}
        </div>

        <small>
          Administratorem Danych Osobowych jest Adream Sp. z o.o. z siedzibą w
          Krakowie, ul. Chopina 18/3a, KRS: 0000517132. Podanie danych osobowych
          w CV jest dobrowolne, lecz konieczne do przeprowadzenia procesu
          rekrutacyjnego. Konsekwencją niepodania danych osobowych będzie brak
          możliwości przeprowadzenia postępowania rekrutacyjnego. Dane osobowe
          przetwarzane będą na podstawie art. 6 ust. 1 pkt. a i c ogólnego
          rozporządzenia o ochronie danych osobowych z 27 kwietnia 2016 r.
          (RODO). Przysługuje Pani/ Panu prawo dostępu do treści swoich danych
          osobowych oraz prawo ich sprostowania, usunięcia, ograniczenia
          przetwarzania, prawo do przenoszenia danych, prawo do wniesienia
          sprzeciwu, prawo do cofnięcia zgody na ich przetwarzanie w dowolnym
          momencie bez wpływu na zgodność z prawem przetwarzania, którego
          dokonano na podstawie zgody przed jej cofnięciem. Przysługuje
          Pani/Panu prawo wniesienia skargi na przetwarzanie danych do Prezesa
          Urzędu Ochrony Danych Osobowych. Dane osobowe będą przetwarzane do
          momentu zakończenia prowadzenia rekrutacji, nie dłużej jednak niż
          przez trzy miesiące od dnia przesłania CV. Za zgodą kandydatów w
          okresie 1 roku od zakończenia procesu rekrutacyjnego dane osobowe będą
          przetwarzane w kolejnych rekrutacjach prowadzonych przez
          administratora danych osobowych. Dane osobowe kandydatów nie będą
          podlegały zautomatyzowanemu przetwarzaniu.
        </small>

        <div className="approvals">
          <Check
            register={register}
            validation={{ required: "Wymagane" }}
            name="agree_1"
            size={"sm"}
            errors={errors}
            label="Wyrażam zgodę na przetwarzanie moich danych osobowych przez Adream sp. z o.o. z siedzibą w Krakowie, ul. Chopina 18/3a, KRS: 0000517132 (Administrator danych) w celu udzielenia mi dodatkowych informacji handlowych z zakresu oferty Agencji. Podanie danych jest dobrowolne i umożliwia uzyskanie informacji handlowej."
          />
          <Check
            register={register}
            name="agree_2"
            size="sm"
            label="Wyrażam zgodę na przetwarzanie moich danych osobowych przez Adream sp. z o.o. z siedzibą w Krakowie, ul. Chopina 18/3a, KRS: 0000517132 (Administrator danych), w celu marketingu bezpośredniego dotyczącego własnych produktów i usług."
          />
          <Check
            register={register}
            name="agree_3"
            size="sm"
            label="Wyrażam zgodę na udostępnienie moich danych osobowych przez Adream sp. z o.o., z siedzibą w Krakowie, ul. Chopina 18/3a, KRS: 0000517132 jako administratora moich danych osobowych, podmiotowi: When Sp. z o. o. z siedzibą w Krakowie, ul. Chopina 18/3a, KRS: 0000683438 (kolejnemu Administratorowi danych), w celu udzielenia mi dodatkowych informacji handlowych z zakresu oferty When sp. z o.o. Podanie danych jest dobrowolne i umożliwia uzyskanie informacji handlowej.*"
          />
        </div>
        <button type="submit" className="btn btn-default">
          Aplikuj
          <Confetti active={showConfetti} config={config} />
        </button>
      </form>
      {isLoading && <Loader />}
    </div>
  );
};

export default RecrutationForm;

import React from 'react'

const LazySpinner = () => (
  <div
    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
  >
    <img src={require("../assets/img/adream_loader.gif")} alt="" />
  </div>
);
 
export default LazySpinner;
import React from "react";
import Slider from "react-slick";
import { Link } from "gatsby";

import "./publicationslider.scss";

const PublicationSlider = () => {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 400,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="PublicationSlider">
      <Slider {...settings}>
        <div className="pslider-wrapper">
          <div className="row publication-row">
            <div className="col-4">
              <a href="/marketing-dla-deweloperow/inmag-01-2020.pdf">
                <img
                  src={require("../../assets/img/mdd/publication_1.png")}
                  className="img-fluid"
                  alt=""
                />
              </a>
            </div>
            <div className="col-8 publication-col">
              <h6>Magazyn Inmag. Numer 1(3)/2020</h6>
              <h4>
                Siła optymalizacji. Kampanie Google Ads dla inwestycji
                deweloperskich
              </h4>
              <a
                href="/marketing-dla-deweloperow/inmag-01-2020.pdf"
                className="btn_custom"
              >
                Pobierz publikację
              </a>
            </div>
          </div>
          <p className="publication-paragraph">
            “ […] ponad 1000 zapytań na minutę w Google dotyczy właśnie
            nieruchomości. W czasach tak łatwego dostępu do informacji, jakie
            zapewnił internet, deweloperzy prześcigają się w działaniach
            reklamowych – rezygnując powoli z działań offline na rzecz kanałów
            mierzalnych, uzyskujących konkretne dane. To właśnie te dane, tak
            jak w przypadku reklamy Google Ads, stanowią o potencjalne kampanii
            reklamowych online, ponieważ tam gdzie są dane, tam pojawia się pole
            do ich analizy i popartej statystykami poprawy prowadzonych
            działań.”
          </p>

          <p className="publication-signature">
            <strong>Patrycja Idzińska</strong>
            <br />
            CEO Agencji WHEN
          </p>
        </div>

        <div className="pslider-wrapper">
          <div className="row publication-row">
            <div className="col-4">
              <Link to="/blog/branding/kreatywnosc-w-sluzbie-sprzedazy-czyli-nasz-artykul-dla-magazynu-inmag/">
                <img
                  src={require("../../assets/img/mdd/publication_2.png")}
                  className="img-fluid"
                  alt=""
                />
              </Link>
            </div>
            <div className="col-8 publication-col">
              <h6>Magazyn Inmag. Numer 3(5)/2020</h6>
              <h4>
                Kreatywność w służbie sprzedaży. Jak wyróżnić się na tle
                konkurencji i dać się zapamiętać?
              </h4>
              <Link
                to="/blog/branding/kreatywnosc-w-sluzbie-sprzedazy-czyli-nasz-artykul-dla-magazynu-inmag/"
                className="btn_custom"
              >
                Pobierz publikację
              </Link>
            </div>
          </div>
          <p className="publication-paragraph">
            “ Od lat buduje się dużo, inwestycja goni inwestycję, a klienci
            często gubią się w gąszczu dostępnych ofert. Przeglądając ogłoszenia
            wystawiane przez biura sprzedaży czy też obcując z marką w
            przestrzeni miejskiej, nie trudno o to, aby inwestycja została
            pominięta. Co wpływa na przyciągnięcie uwagi? Jak dać się zapamiętać
            i wytworzyć w kupujących emocje, które sprawią, że to właśnie Twoja
            inwestycja stanie się obiektem pożądania? ”
          </p>

          <p className="publication-signature">
            <strong>Wojciech Kmiecik</strong>
            <br />
            Creative Director ADream
          </p>
        </div>

        <div className="pslider-wrapper">
          <div className="row publication-row">
            <div className="col-4">
              <a href="/marketing-dla-deweloperow/inmag-03-2021.pdf">
                <img
                  src={require("../../assets/img/mdd/publication_3.png")}
                  className="img-fluid"
                  alt=""
                />
              </a>
            </div>
            <div className="col-8 publication-col">
              <h6>Magazyn Inmag. Numer 3(9)/2021</h6>
              <h4>Nie unikaj krytyki, czyli świadomy dialog z klientem</h4>
              <a
                href="/marketing-dla-deweloperow/inmag-03-2021.pdf"
                className="btn_custom"
              >
                Pobierz publikację
              </a>
            </div>
          </div>
          <p className="publication-paragraph">
            “Branża deweloperska nie należy do łatwych. Tu sprzedaż wymaga dużej
            dozy zaufania i świadomości produktu. Powracającym problemem na
            poziomie komunikacji jest prowadzenie monologu i ignorowanie
            sygnałów od uzytkowników. Dlatego poka atuty inwestycji, przekuwaj
            wady w zalety, słuchaj i rozmawiaj z potencjalnymi klientami.”
          </p>

          <p className="publication-signature">
            <strong>Aleksandra Żurek</strong>
            <br />
            Social Media Specialist Agencji WHEN
          </p>
        </div>

        <div className="pslider-wrapper">
          <div className="row publication-row">
            <div className="col-4">
              <a href="/marketing-dla-deweloperow/inmag-02-2020.pdf">
                <img
                  src={require("../../assets/img/mdd/publication_4.png")}
                  className="img-fluid"
                  alt=""
                />
              </a>
            </div>
            <div className="col-8 publication-col">
              <h6>Magazyn Inmag. Numer 2(4)/2020</h6>
              <h4>
                Nie zgaduj. Testy A/B strony inwestycji bez ingerencji
                programisty
              </h4>
              <a
                href="/marketing-dla-deweloperow/inmag-02-2020.pdf"
                className="btn_custom"
              >
                Pobierz publikację
              </a>
            </div>
          </div>
          <p className="publication-paragraph">
            “ […] Z uwagi na mierzalność i potencjał dotarcia do
            wyselekcjonowanej grupy docelowej, działania te coraz częściej są
            opierane przez deweloperów na prowadzeniu płatnej kampanii Google
            Ads. Mając na uwadze bardzo duże znaczenie tego kanału reklamowego
            dla sprzedaży inwestycji obecnie, ważna jest jego poprawna
            konfiguracja oraz późniejsza analiza otrzymanych danych.”
          </p>

          <p className="publication-signature">
            <strong>Patrycja Idzińska</strong>
            <br />
            CEO Agencji WHEN
          </p>
        </div>

        <div className="pslider-wrapper">
          <div className="row publication-row">
            <div className="col-4">
              <a href="/marketing-dla-deweloperow/inmag-04-2020.pdf">
                <img
                  src={require("../../assets/img/mdd/publication_5.png")}
                  className="img-fluid"
                  alt=""
                />
              </a>
            </div>
            <div className="col-8 publication-col">
              <h6>Magazyn Inmag. Numer 4(6)/2020</h6>
              <h4>
                Serwis www główny specjalista ds. Wsparcia działu sprzedaży
              </h4>
              <a
                href="/marketing-dla-deweloperow/inmag-04-2020.pdf"
                className="btn_custom"
              >
                Pobierz publikację
              </a>
            </div>
          </div>
          <p className="publication-paragraph">
            “Przystępując do projektowania serwisu internetowego inwestycji
            mieszkaniowej, powinniśmy dysponować już wszystkimi danymi i
            założeniami opracowanymi na etapie strategicznym. Powinniśmy
            wiedzieć, jaki charakter ma stworzona właśnie marka oraz jak i do
            kogo mamy kierować komunikację. ”
          </p>

          <p className="publication-signature">
            <strong>Wojciech Kmiecik</strong>
            <br />
            Creative Director ADream
          </p>
        </div>
      </Slider>
    </section>
  );
};

export default PublicationSlider;

import React from "react";

const Check = ({ type, name, label, size, register, errors, validation, required, white }) => {
   return (
      <div
         className={`check-group ${size ? size : ""} ${errors && errors[name] ? "has-error" : ""} ${
            white ? `check-group--white` : ""
         }`}
      >
         <input
            type="checkbox"
            id={name}
            name={name}
            ref={register(validation || {})}
            required={required}
         />
         <label htmlFor={name}>
            {label}
            {validation && <sup>*</sup>}
            {errors && errors[name] && (
               <span role="alert" className="error">
                  {errors[name].message}
               </span>
            )}
         </label>
      </div>
   );
};

export default Check;

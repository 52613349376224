import React from "react";

const SvgArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    viewBox="0 0 34 34"
  >
    <defs>
      <clipPath id="f0q7a">
        <path
          fill="#fff"
          d="M17 0c9.389 0 17 7.611 17 17s-7.611 17-17 17S0 26.389 0 17 7.611 0 17 0z"
        />
      </clipPath>
    </defs>
    <g>
      <g>
        <path
          fill="#cbcbcb"
          d="M20.659 16.077l-5.655-4.803c-1.107-.94-2.736.623-1.622 1.568l4.75 4.036-4.786 4.246c-1.091.968.535 2.535 1.623 1.57l5.69-5.048c.439-.389.456-1.181 0-1.569z"
        />
      </g>
      <g>
        <path
          fill="none"
          stroke="#cbcbcb"
          strokeMiterlimit="50"
          strokeWidth="4"
          d="M17 0c9.389 0 17 7.611 17 17s-7.611 17-17 17S0 26.389 0 17 7.611 0 17 0z"
          clipPath='url("#f0q7a")'
        />
      </g>
    </g>
  </svg>
);
export default SvgArrow;

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import Slider from "react-slick";
import projects from "../data/portfolio.json";

const PortfolioNav = ({ data: { initialSlideIndex = 0 }, locales }) => {
  const [slider_index, setSliderIndex] = useState(0);

  useEffect(() => {
    setSliderIndex(slider_index + 1);
  }, []);

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 400,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: initialSlideIndex,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1099,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="portfolio_section_nav">
      <h2>{locales.portfolio_navs.portfolio_other}</h2>
      <Slider {...settings} key={slider_index}>
        {projects.map(({ categories, name, var: id, url }) => {
          categories = categories.map((category) => `target${category}`);
          const img_front = require(`../assets/img/portfolio_grid/${id}_back.png`);
          const img_back = require(`../assets/img/portfolio_grid/${id}_front.png`);
          return (
            <div key={id}>
              <Link
                to={`/projekty${url}`}
                className={`portfolio_tile targetAll ${categories.join(" ")}`}
              >
                <h3>
                  {name}
                  <span>
                    {Object.keys(locales.portfolio_navs).map((key) => {
                      if (key === id) {
                        return locales.portfolio_navs[key];
                      } else {
                        return null;
                      }
                    })}
                  </span>
                </h3>
                <div
                  className="portfolio_tile-back"
                  style={{
                    backgroundImage: `url(${img_front})`,
                  }}
                />
                <img className="img-fluid" src={img_back} alt="" />
              </Link>
            </div>
          );
        })}
      </Slider>
    </section>
  );
};

export default PortfolioNav;

import React, { Component } from "react";
import scrollToElement from "scroll-to-element";

class CaseStudyHeader extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this.heroFade);
  }
  heroFade = () => {
    const heroFade = window.pageYOffset || document.documentElement.scrollTop;
    const header = document.getElementsByClassName("heroFade")[0];
    if (!header) return false;
    header.style.transform = "scale(" + (100 - heroFade / 50) / 98 + ")";
    header.style.opacity = 1 - heroFade / 40 / 50;
    header.style.transition = "0.2s";
  };
  render() {
    const { height, logo, background, title } = this.props;
    return (
      <section className="heroFadeWrapper">
        {/* eslint-disable jsx-a11y/control-has-associated-label */}
        <div
          className={`container-fluid heroFade`}
          style={{ backgroundImage: `url(${background})` }}
        >
          <img
            className="img-fluid"
            src={`${logo}`}
            style={{
              maxHeight: `${height}px`,
            }}
            alt={`${title} Logo`}
          />
          <button
            className="scrollPortfolio"
            onClick={() => scrollToElement("#info")}
          />
        </div>
      </section>
    );
  }
}

export default CaseStudyHeader;

import React, { Component } from "react";
import { CookiesProvider, withCookies } from "react-cookie";
import { Link } from "gatsby";
import { SEO } from "./";
import "../styles/theme.scss";
import locales from "../constants";

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]');
}

class LayoutBehance extends Component {
  state = {
    cookieOpen: false,
  };
  componentDidMount() {
    const { cookies } = this.props;
    const isAcceptedCoookie = !!cookies.get("cookie-accept");
    !isAcceptedCoookie && this.setState({ cookieOpen: true });
  }

  acceptCookie = () => {
    const { cookies } = this.props;

    const promiseSetCookie = new Promise((resolve) =>
      resolve(cookies.set("cookie-accept", "active", { path: "/" }))
    );
    promiseSetCookie.then(() => {
      this.setState({ cookieOpen: false });
    });
  };

  render() {
    const { children, seo } = this.props;
    return (
      <CookiesProvider>
        <SEO {...seo} />
        <main>{children}</main>
        <div
          className={`cookie_bar ${this.state.cookieOpen ? "open" : ""}`}
          id="mainBelt"
        >
          <p>{locales[seo.lang].cookie_info}</p>
          <ul>
            <li>
              <button className="accept_btn" onClick={this.acceptCookie}>
                Ok
              </button>
            </li>
            <li>
              <Link
                to={locales[seo.lang].privacy_policy_href}
                className="accept_btn"
                target="_blank"
                rel="noopener noreferrer"
              >
                {locales[seo.lang].more_info}
              </Link>
            </li>
          </ul>
        </div>
      </CookiesProvider>
    );
  }
}

export default withCookies(LayoutBehance);
